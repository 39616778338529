/** @jsx jsx */
import { useEffect } from 'react'
import {
  useAnalytics,
  useCart,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  jsx,
  Spinner,
  Text,
} from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import CartFooter from '~/components/Cart/Footer'
import GetWineGiveWine from '~/components/Cart/GetWineGiveWine'
import CartShippingMeter from '~/components/Cart/Meter/Shipping'
import PromoCode from '~/components/Cart/PromoCode'
import {
  getAllCartPromotions,
  getAllCartPromotionsForDisplay,
} from '~/utils/promotions'

const CartContainer = ({ children }) => {
  const translate = useTranslate()

  return (
    <Box pt={[7, 9]} pb={[7, 14]}>
      <Container>
        <Heading as="h1" variant="title1" sx={{ textAlign: 'center' }}>
          {translate('cart.title')}
        </Heading>
        {children}
      </Container>
    </Box>
  )
}

const CartLoading = () => {
  return (
    <CartContainer>
      <Box py={10} sx={{ textAlign: 'center' }}>
        <Spinner
          color="brandTertiary"
          size="100"
          sx={{
            '@media (prefers-reduced-motion)': {
              circle: {
                animationIterationCount: 8,
              },
            },
          }}
        />
      </Box>
    </CartContainer>
  )
}

const CartEmpty = () => {
  const translate = useTranslate()

  return (
    <CartContainer>
      <Box py={7} sx={{ textAlign: 'center' }}>
        <Text as="p" variant="body1" mb={3}>
          {translate('cart.empty')}
        </Text>
        <Button as={Link} to="/shop/" variant="secondary">
          {translate('cart.shop_button')}
        </Button>
      </Box>
    </CartContainer>
  )
}

const CartNotEmpty = ({
  allPromotions,
  lineItems,
  displayItemTotal,
  displayPromotions,
  displayShipTotal,
  displayStoreCreditRemainingAfterCapture,
  displayTaxTotal,
  displayTotal,
  displayTotalApplicableStoreCredit,
  giftNote,
  isGiftCardsOnly,
  itemTotal,
  orderTotalAfterStoreCredit,
  shipTotal,
  totalApplicableStoreCredit,
}) => {
  const promoCode = allPromotions ? allPromotions.find((p) => p.code) : null

  return (
    <CartContainer>
      <Grid columns={[1, null, 12]}>
        {!isGiftCardsOnly && (
          <Box mt={4} sx={{ gridColumn: [null, null, '4 / 10'] }}>
            <CartShippingMeter />
            <GetWineGiveWine isOverlay={false} />
          </Box>
        )}
      </Grid>
      <Grid columns={[1, null, 2]} mt={7}>
        <Box>
          <Box px={3} py={1} sx={{ backgroundColor: 'white' }}>
            <LineItems items={lineItems} />
            <Box py={2}>
              <PromoCode promotion={promoCode} allPromotions={allPromotions} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ backgroundColor: 'white' }}>
            <CartFooter
              displayItemTotal={displayItemTotal}
              displayPromotions={displayPromotions}
              displayShipTotal={displayShipTotal}
              displayStoreCreditRemainingAfterCapture={
                displayStoreCreditRemainingAfterCapture
              }
              displayTaxTotal={displayTaxTotal}
              displayTotal={displayTotal}
              displayTotalApplicableStoreCredit={
                displayTotalApplicableStoreCredit
              }
              giftNote={giftNote}
              isGiftCardsOnly={isGiftCardsOnly}
              isGiftMessageOverlay={false}
              itemTotal={itemTotal}
              orderTotalAfterStoreCredit={orderTotalAfterStoreCredit}
              shipTotal={shipTotal}
              totalApplicableStoreCredit={totalApplicableStoreCredit}
            />
          </Box>
        </Box>
      </Grid>
    </CartContainer>
  )
}

const CartPage = () => {
  const { cart, isLoaded } = useCart()
  const { trackCartViewed } = useAnalytics()

  useEffect(() => {
    if (isLoaded) trackCartViewed()
  }, [isLoaded, trackCartViewed])

  const {
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    displayStoreCreditRemainingAfterCapture,
    displayTotalApplicableStoreCredit,
    giftNote,
    itemTotal,
    lineItems,
    orderTotalAfterStoreCredit,
    shipTotal,
    totalApplicableStoreCredit,
  } = cart

  if (!lineItems) return <CartLoading />
  if (lineItems && lineItems.length === 0) return <CartEmpty />

  const allPromotions = getAllCartPromotions(cart)
  const displayPromotions = getAllCartPromotionsForDisplay(allPromotions)

  const isGiftCardsOnly =
    lineItems.filter((lineItem) => !lineItem.giftCards.length).length === 0

  return (
    <CartNotEmpty
      allPromotions={allPromotions}
      lineItems={lineItems}
      displayItemTotal={displayItemTotal}
      displayPromotions={displayPromotions}
      displayShipTotal={displayShipTotal}
      displayStoreCreditRemainingAfterCapture={
        displayStoreCreditRemainingAfterCapture
      }
      displayTaxTotal={displayTaxTotal}
      displayTotal={displayTotal}
      displayTotalApplicableStoreCredit={displayTotalApplicableStoreCredit}
      giftNote={giftNote}
      isGiftCardsOnly={isGiftCardsOnly}
      itemTotal={itemTotal}
      orderTotalAfterStoreCredit={orderTotalAfterStoreCredit}
      shipTotal={shipTotal}
      totalApplicableStoreCredit={totalApplicableStoreCredit}
    />
  )
}

CartNotEmpty.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  displayTotal: PropTypes.string,
  displayItemTotal: PropTypes.string,
  displayPromotions: PropTypes.arrayOf(PropTypes.shape({})),
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  displayTotalApplicableStoreCredit: PropTypes.string,
  displayOrderTotalAfterStoreCredit: PropTypes.string,
}

export default CartPage
